import { EventEmitter, Injectable, inject } from '@angular/core';
import { EnvironmentConfig } from 'libs/infrastructure';
import { ThemeUrls } from 'libs/infrastructure/theme/theme-urls';
import { BrandingTheme, CustomerBranding } from '@ui/shared/models';
import {
  adjustColorLightness,
  getColorDark,
  getColorPrimaryAccentLight,
  getColorSecondaryAccentLight,
  getContrastingBWColor
} from 'libs/infrastructure/theme/theme.utils';

import { ImmomioTheme } from 'libs/infrastructure/theme/themes';
import {
  ACTIVE_THEME,
  Theme,
  ThemeProperties,
  ThemePropertyKeys,
  THEMES
} from './symbols';

@Injectable()
export class ThemeService {
  themes = inject(THEMES);
  theme = inject(ACTIVE_THEME);

  public environment: EnvironmentConfig;

  public themeChange = new EventEmitter<Theme>();
  private defaultTheme: string = ThemeUrls.IMMOMIO;

  public static processName(name: string) {
    return name?.toLowerCase()?.replace(/ /g, '-');
  }

  public init(environment: EnvironmentConfig) {
    this.environment = environment;
    if (environment.app_design) this.defaultTheme = environment.app_design;
  }

  public isCurrentAppDesign(app_design: ThemeUrls) {
    return this.environment?.app_design === app_design;
  }

  public getTheme(name: string = ThemeUrls.IMMOMIO) {
    const theme = this.findTheme(name);
    if (!theme) {
      throw new Error(`Theme not found: '${name.toLowerCase()}'`);
    }
    return theme;
  }

  public get getDefaultTheme() {
    return this.defaultTheme;
  }

  public get getActiveTheme() {
    return this.getTheme(this.theme);
  }

  public getProperty(propName: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.getActiveTheme.properties[propName];
  }

  public setTheme(name: string) {
    this.theme = name;
    this.themeChange.emit(this.getActiveTheme);
  }

  public registerTheme(theme: Theme) {
    const index = this.themes.findIndex(t => t.name === theme.name);
    if (theme.name === this.getActiveTheme.name && index >= 0) {
      this.themes[index] = theme;
      this.themeChange.emit(theme);
      return;
    }
    this.themes.push(theme);
  }

  public createTheme(brandingTheme: CustomerBranding) {
    if (!brandingTheme?.theme) return;
    const { logo, favicon, iTPLogo, theme, pageTitle } = brandingTheme;
    if (this.findTheme(brandingTheme?.theme?.name)) {
      return this.updateTheme(theme);
    }
    const landlordTheme = {
      name: ThemeService.processName(theme?.name),
      logo: logo,
      favicon: favicon,
      iTPLogo: iTPLogo,
      pageTitle: pageTitle,
      properties: this.updateThemeProperty(theme)
    };

    this.registerTheme(landlordTheme);
    this.setTheme(landlordTheme.name);
  }

  public updateTheme(newTheme: BrandingTheme) {
    const theme = this.getTheme(ThemeService.processName(newTheme.name));
    theme.properties = this.updateThemeProperty(newTheme);
    const index = this.themes.findIndex(
      t =>
        ThemeService.processName(t.name) ===
        ThemeService.processName(newTheme.name)
    );
    this.themes[index] = theme;
    if (newTheme.name === this.theme) {
      this.themeChange.emit(theme);
    }
  }

  private updateThemeProperty(theme: BrandingTheme): ThemeProperties {
    const colorPrimaryAccentLight = getColorPrimaryAccentLight(
      theme.primaryColor
    );
    const colorPrimaryAccentDark = getColorDark(theme.primaryColor);
    const colorSecondaryAccentLight = getColorSecondaryAccentLight(
      theme.secondaryColor
    );
    const colorSecondaryAccentLighter = adjustColorLightness(
      theme.secondaryColor,
      0.93
    );
    const colorSecondaryAccentDark = getColorDark(theme.secondaryColor);

    return {
      ...ImmomioTheme.properties,

      [ThemePropertyKeys.THEME_COLOR_APP_BACKGROUND]: theme.backgroundColor,
      [ThemePropertyKeys.THEME_COLOR_CARD_BACKGROUND]:
        theme.cardBackgroundColor,

      [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT]: theme.primaryColor,
      [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_BW_CONTRAST]:
        getContrastingBWColor(theme.primaryColor),
      [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_LIGHT]:
        colorPrimaryAccentLight,
      [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_LIGHT_BW_CONTRAST]:
        getContrastingBWColor(colorPrimaryAccentLight),
      [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_DARK]:
        colorPrimaryAccentDark,
      [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_DARK_BW_CONTRAST]:
        getContrastingBWColor(colorPrimaryAccentDark),

      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT]: theme.secondaryColor,
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_BW_CONTRAST]:
        getContrastingBWColor(theme.secondaryColor),
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHT]:
        colorSecondaryAccentLight,
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHT_BW_CONTRAST]:
        getContrastingBWColor(colorSecondaryAccentLight),
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER]:
        colorSecondaryAccentLighter,
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER_BW_CONTRAST]:
        getContrastingBWColor(colorSecondaryAccentLighter),
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_DARK]:
        colorSecondaryAccentDark,
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_DARK_BW_CONTRAST]:
        getContrastingBWColor(colorSecondaryAccentDark),

      [ThemePropertyKeys.THEME_COLOR_PRIMARY_TEXT]: theme.primaryTextColor,
      [ThemePropertyKeys.THEME_COLOR_SECONDARY_TEXT]: theme.secondaryTextColor,
      [ThemePropertyKeys.THEME_COLOR_BUTTON_TEXT]: theme.buttonTextColor,
      [ThemePropertyKeys.THEME_COLOR_MENU_BAR_BACKGROUND]:
        theme.menuBarBackgroundColor || theme.cardBackgroundColor,
      [ThemePropertyKeys.THEME_COLOR_MENU_BAR_ITEM_ACTIVE]:
        theme.menuBarTextColorActive || theme.primaryColor,
      [ThemePropertyKeys.THEME_COLOR_MENU_BAR_ITEM_INACTIVE]:
        theme.menuBarTextColorInactive || '#000000'
    };
  }

  private findTheme(name: string) {
    return this.themes?.find(
      t => ThemeService.processName(t?.name) === ThemeService.processName(name)
    );
  }
}
