import { Injectable, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { distinctUntilChanged } from 'rxjs/operators';
import moment from 'moment';

import { isValidDateValidator } from 'libs/components/legacy/form/controls/validation/validators';
import {
  Address,
  AvailableLanguageCodesEnum,
  CertificateCreationDates,
  ConditionId,
  CustomerDefinedFieldValue,
  CustomerLocation,
  EnergyCertificate,
  EnergyCertificateType,
  HomepageModule,
  MarketingType,
  ObjectType,
  PortalCredential,
  Prioset,
  Property,
  PropertyType,
  PropertyAttachmentsTypes,
  ConversationRestrictionConfig,
  UserSettingsConversationConfig
} from '@ui/shared/models';
import {
  defaultHousingCondition,
  getEmptyPrioset,
  PriosetCondition
} from '@ui/shared/models';
import {
  forbiddenCharactersValidator,
  integerValidator
} from 'libs/components/legacy/form';
import { DEFAULT_CONVERSATION_RESTRICTION_CONFIG } from 'libs/components/legacy/messenger/model/enum';
import { DateTimeService } from 'libs/services';
import {
  isPropertyTypeGarage,
  RegexTypes,
  stripTypenameProperty,
  getMultiLanguageStringFormGroupControlsConfig
} from 'libs/utils';

@UntilDestroy()
@Injectable()
export class PropertyFormManager {
  private fb = inject(FormBuilder);
  private translate = inject(TranslateService);
  private dateTimeService = inject(DateTimeService);

  // year of construction can be not higher than 3 years in the future
  private static MAX_CONSTRUCTION_YEAR = new Date().getFullYear() + 3;
  private static TEXTAREAS_FORBIDDEN_CHARACTERS = [
    '<',
    '>',
    '*',
    '#',
    '+',
    '~',
    '$',
    '[',
    ']',
    '{',
    '}'
  ];

  public form: FormGroup;

  public get basicInformationForm() {
    return this.form.get('basicInformation') as FormGroup;
  }

  public get addressForm() {
    return this.basicInformationForm.get('address') as FormGroup;
  }

  public get propertyManagerId() {
    return this.basicInformationForm.get('propertyManagerId') as FormGroup;
  }

  public get propertyManagerName() {
    return this.basicInformationForm.get('propertyManagerName') as FormGroup;
  }

  public get previousTenant() {
    return this.basicInformationForm.get('previousTenant') as FormGroup;
  }

  public get previousTenantAppointmentEnabled() {
    return this.basicInformationForm.get(
      'previousTenantAppointmentEnabled'
    ) as FormGroup;
  }

  public get desiredTenantForm() {
    return this.form.get('desiredTenant') as FormGroup;
  }

  public get detailedInformationForm() {
    return this.form.get('detailedInformation') as FormGroup;
  }

  public get conversationConfigsControl() {
    return this.detailedInformationForm.get('conversationConfigs');
  }

  public get allowRuvDepositControl() {
    return this.detailedInformationForm.get('allowRuvDeposit');
  }

  public get autoDeactivateConfigControl() {
    return this.newPropertyPortalsForm.get('autoDeactivateConfig');
  }

  public get autoActivateConfigControl() {
    return this.newPropertyPortalsForm.get('autoActivateConfig');
  }

  public get newPropertyDocumentsForm() {
    return this.form.get('newPropertyDocuments') as FormGroup;
  }

  public get newPropertyPortalsForm() {
    return this.form.get('newPropertyPortals') as FormGroup;
  }

  public get automatedRentalControl() {
    return this.newPropertyPortalsForm.get(
      'automatedRentalProcess'
    ) as FormControl;
  }

  public get energyCertificateForm() {
    return this.detailedInformationForm.get('energyCertificate') as FormGroup;
  }

  public get objectTypeControl() {
    return this.detailedInformationForm.get('objectType');
  }

  public get WBSSetting() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.basicInformationForm.get('wbs').value;
  }

  public get propertyType() {
    return this.basicInformationForm.get('type');
  }

  public get marketingType() {
    return this.basicInformationForm.get('marketingType');
  }

  public get propertySizeBasis() {
    return this.basicInformationForm.get('size');
  }

  public get propertySizeDetail() {
    return this.detailedInformationForm.get('size');
  }

  public get commercialDataForm() {
    return this.detailedInformationForm.get('commercialData') as FormGroup;
  }

  public get initEmptyPrioset() {
    return {
      ...getEmptyPrioset(),
      name: this.translate.instant('tenant_profile.default_form_name_l'),
      description: this.translate.instant(
        'tenant_profile.default_form_description_l'
      )
    };
  }

  public setDefaultDesiredTenant() {
    const defaultForm = this.initEmptyPrioset;
    this.desiredTenantForm.patchValue({
      selectedProfile: defaultForm,
      profile: defaultForm
    });
  }

  public patch(property: Property) {
    const {
      administrationUnit,
      basePrice,
      totalRentGross,
      totalRentGrossManuallySet,
      externalId,
      externalIdPart,
      rooms,
      halfRooms,
      showAddress,
      size,
      prioset,
      documents,
      name,
      writeProtection,
      bathRooms,
      basementSize,
      buildingCondition,
      flatType,
      floor,
      ground,
      availableFrom,
      constructionYear,
      heater,
      heatingCost,
      heatingCostIncluded,
      otherCosts,
      guestToilette,

      houseType,
      objectType,
      numberOfFloors,
      fireplace,
      gardenUse,
      washDryRoom,
      storeRoom,
      bicycleRoom,
      attic,
      seniors,
      landArea,
      parkingSpaces,
      parkingPriceIncludedToAdditionalCosts,

      kitchenette,
      historicBuilding,
      tvSatCable,
      garden,
      barrierFree,
      elevator,
      objectDescription,
      objectLocationText,
      objectMiscellaneousText,
      furnishingDescription,
      portals,
      bailment,
      serviceCharge,
      selfDisclosureId,
      salesData,
      settings,
      previousTenantAppointmentEnabled,
      propertyManagerAgentInfo,
      type,
      marketingType,
      commercialData,
      garageData,

      numberOfBalconies,
      numberOfTerraces,
      numberOfLoggias,
      balconyTerraceArea,
      numberOfBedrooms,
      bathroomEquipment,
      furnishingType,
      washingMachineConnection,
      washingMachineConnectionPlaces,
      shutter,
      intercomType,
      basementAvailable,
      wheelchairAccessible,
      entryPrice,
      externalResources,
      customerDefinedFieldValues,
      expose,
      pricesWriteProtected,
      courtageData
    } = property;

    const {
      allowRuvDeposit = false,
      showLegalTextAndCheckboxes = false,
      disableEntranceFee = false
    } = settings || {};
    const conversationConfigs =
      settings?.conversationConfig || this.conversationConfigsControl.value;

    const autoDeactivateConfig =
      settings?.autoDeactivateConfig || this.autoDeactivateConfigControl.value;
    const autoDeactivateEnabled = !!(
      autoDeactivateConfig?.type && autoDeactivateConfig?.amount
    );

    const autoActivateConfig =
      settings?.autoActivateConfig || this.autoActivateConfigControl.value;
    const autoActivateDate = autoActivateConfig?.activationDate
      ? moment(autoActivateConfig?.activationDate).toDate()
      : null;
    const autoActivateEnabled = !!autoActivateDate;
    const autoActivateTime = autoActivateDate
      ? this.dateTimeService.getTimeFromISOString(
          autoActivateConfig?.activationDate
        )
      : null;

    const address = this.getAddressModel(
      property?.address,
      !!property?.district
    );
    const userID = property.user && property.user.id;
    const energyCertificate = this.getEnergyCertificateModel(
      property.energyCertificate || {}
    );
    const previousTenant = property.previousTenant || {};

    const attachments = property.attachments || [];
    const propertyDocuments = documents ? [...documents] : [];
    const coverImage = attachments.length ? attachments[0] : null;
    const imagesAndVideos = attachments.length > 1 ? attachments.slice(1) : [];

    // Imported properties via the public API will only have the value documentType filled type is then null
    const floorPlan = propertyDocuments.find(
      item =>
        item.documentType === PropertyAttachmentsTypes.FLOOR_PLAN ||
        item.type === PropertyAttachmentsTypes.FLOOR_PLAN
    );
    if (floorPlan) {
      const index = propertyDocuments.indexOf(floorPlan);
      propertyDocuments.splice(index, 1);
    }
    const credentials = (property.portals || []).reduce(
      (array: PortalCredential[], currentPortal) => {
        if (currentPortal.credentials != null) {
          array.push(currentPortal.credentials);
        }
        return array;
      },
      []
    );

    const homepages = (property.portals || []).reduce(
      (homepageModules: HomepageModule[], portal) => {
        /* Filter for portals with `homepageModule` properties set
         * to exclude all external portals (= credentials)
         */
        const { homepageModule } = portal;
        if (homepageModule) {
          homepageModules.push(homepageModule);
        }
        return homepageModules;
      },
      []
    );

    const objectTypeToPatch = objectType || ObjectType.FLAT;

    let propertyManagerName = null;
    if (propertyManagerAgentInfo) {
      propertyManagerName = `${propertyManagerAgentInfo?.firstName} ${propertyManagerAgentInfo?.name}`;
    }
    let prioSetInit = { ...prioset } || this.initEmptyPrioset;
    const hasWBSCondition = prioSetInit.data?.conditions?.some(
      condition => condition.data.conditionId === ConditionId.HOUSING_PERMISSION
    );
    const conditions = (
      prioSetInit.data?.conditions as PriosetCondition[]
    )?.map(condition => ({ ...condition, value: condition.value ?? true }));
    const wbs = hasWBSCondition
      ? {
          value: true,
          knockout: true
        }
      : {
          value: false,
          knockout: false
        };

    prioSetInit = {
      ...prioSetInit,
      data: {
        ...prioSetInit.data,
        conditions: hasWBSCondition
          ? conditions
          : [...(conditions || []), defaultHousingCondition]
      }
    };

    const patchData = {
      basicInformation: {
        basePrice,
        coverImage,
        rooms,
        halfRooms,
        floorPlan,
        showAddress,
        size,
        wbs,
        previousTenant,
        previousTenantAppointmentEnabled,
        type: type || PropertyType.FLAT,
        marketingType: marketingType || MarketingType.RENT,
        externalId,
        propertyManagerId: propertyManagerAgentInfo?.id,
        administrationUnitId: administrationUnit?.id,
        externalIdPart,
        propertyManagerName,
        salesData,
        showLegalTextAndCheckboxes,
        districtId: property.district?.id
      },
      desiredTenant: {
        profile: prioSetInit,
        selectedProfile: prioSetInit,
        selfDisclosureId
      },
      detailedInformation: {
        name,
        userID,
        writeProtection,
        bathRooms,
        basementSize,
        buildingCondition,
        flatType,
        floor,
        availableFrom: availableFrom || {},
        heater,
        heatingCost,
        energyCertificate,
        guestToilette,
        kitchenette,
        historicBuilding,
        houseType,
        objectType: objectTypeToPatch,
        numberOfFloors,
        fireplace,
        gardenUse,
        washDryRoom,
        storeRoom,
        bicycleRoom,
        attic,
        seniors,
        landArea,
        parkingSpaces,
        parkingPriceIncludedToAdditionalCosts,
        garden,
        barrierFree,
        elevator,
        objectDescription,
        objectLocationText,
        objectMiscellaneousText,
        ground,
        bailment,
        salesData,
        serviceCharge,
        furnishingDescription,
        heatingCostIncluded,
        constructionYear,
        conversationConfigs,
        allowRuvDeposit,
        pricesWriteProtected,
        disableEntranceFee,
        tvSatCable,
        numberOfBalconies,
        numberOfTerraces,
        numberOfLoggias,
        balconyTerraceArea,
        numberOfBedrooms,
        bathroomEquipment: bathroomEquipment || {},
        furnishingType,
        garageData: garageData || {},
        commercialData: commercialData || {},
        washingMachineConnection,
        washingMachineConnectionPlaces,
        shutter,
        intercomType,
        basementAvailable,
        wheelchairAccessible,
        entryPrice,
        externalResources,
        totalRentGross: totalRentGrossManuallySet ? totalRentGross : null,
        totalRentGrossManuallySet,
        otherCosts,
        customerDefinedFieldValues: this.mappedCustomerDefinedFieldValues(
          customerDefinedFieldValues
        ),
        courtageData
      },
      newPropertyDocuments: {
        documents: propertyDocuments,
        imagesAndVideos,
        expose
      },
      newPropertyPortals: {
        portals,
        credentials,
        homepages,
        autoDeactivateEnabled,
        autoDeactivateConfig,
        autoActivateEnabled,
        autoActivateConfig: {
          ...autoActivateConfig,
          time: autoActivateTime,
          date: autoActivateDate
        }
      }
    };

    this.form.patchValue(patchData);
    this.basicInformationForm.patchValue({ address }, { emitEvent: false });

    for (const key in AvailableLanguageCodesEnum) {
      const languageCode = AvailableLanguageCodesEnum[key];

      if (objectDescription?.[languageCode]?.length > 0) {
        this.objectDescriptionControl(languageCode)?.markAsTouched();
      }
      if (objectLocationText?.[languageCode]?.length > 0) {
        this.objectLocationTextControl(languageCode)?.markAsTouched();
      }
      if (objectMiscellaneousText?.[languageCode]?.length > 0) {
        this.objectMiscellaneousTextControl(languageCode)?.markAsTouched();
      }
      if (furnishingDescription?.[languageCode]?.length > 0) {
        this.furnishingDescriptionControl(languageCode)?.markAsTouched();
      }
    }
  }

  public patchUserId(userID: string) {
    this.detailedInformationForm.patchValue({ userID });
  }

  public patchConversationConfigs(
    propertyConfig: ConversationRestrictionConfig,
    userSettingsConfig: UserSettingsConversationConfig
  ) {
    const { restrictionConfig: userSettingsRestrictionConfig } =
      userSettingsConfig || {};
    const config =
      propertyConfig && !Object.values(propertyConfig).every(c => !c)
        ? propertyConfig
        : userSettingsRestrictionConfig &&
            !Object.values(
              stripTypenameProperty(userSettingsRestrictionConfig)
            ).every(c => !c)
          ? userSettingsRestrictionConfig
          : DEFAULT_CONVERSATION_RESTRICTION_CONFIG;

    this.conversationConfigsControl.patchValue(config);
  }

  private getEnergyCertificateModel(energyCertificate: EnergyCertificate) {
    return {
      ...energyCertificate,
      usageCertificate:
        energyCertificate && energyCertificate.usageCertificate
          ? { ...energyCertificate.usageCertificate }
          : {},
      demandCertificate:
        energyCertificate && energyCertificate.demandCertificate
          ? { ...energyCertificate.demandCertificate }
          : {}
    } as EnergyCertificate;
  }

  private getAddressModel(address: Address = {}, withDistrict = true) {
    // withDistrict is a fix for ART-12646, the flag should be false when the district ID on a property isn't set
    return {
      ...address,
      district: withDistrict ? address.district : '',
      country: address.country || 'DE',
      countryName: address.country,
      coordinates: address.coordinates || {}
    };
  }

  constructor() {
    this.form = this.fb.group({
      basicInformation: this.fb.group({
        type: [PropertyType.FLAT, Validators.required],
        marketingType: [MarketingType.RENT, Validators.required],
        address: this.fb.group({
          city: ['', Validators.required],
          district: ['', Validators.required],
          country: ['', Validators.required],
          countryName: [{ value: '', disabled: true }],
          houseNumber: ['', Validators.required],
          region: ['', Validators.required],
          street: ['', Validators.required],
          zipCode: ['', Validators.required],
          coordinates: this.fb.group({
            lon: [],
            lat: []
          })
        }),
        floorPlan: [null],
        basePrice: [
          null,
          Validators.compose([Validators.required, Validators.min(0)])
        ],
        salesData: this.fb.group({
          price: [
            { value: null, disabled: true },
            Validators.compose([Validators.required, Validators.min(0)])
          ],
          serviceCharge: [null]
        }),
        externalId: [''],
        administrationUnitId: [null],
        externalIdPart: [''],
        coverImage: [''],
        rooms: [
          null,
          Validators.compose([Validators.required, Validators.min(0)])
        ],
        halfRooms: null,
        showAddress: [true],
        size: null,
        wbs: this.fb.group({
          value: [false],
          knockout: [false]
        }),
        previousTenantAppointmentEnabled: [false],
        propertyManagerId: [''],
        propertyManagerName: [''],
        previousTenant: this.fb.group({
          firstname: [''],
          name: [''],
          email: [''],
          phone: ['']
        }),
        showLegalTextAndCheckboxes: false,
        districtId: ''
      }),
      desiredTenant: this.fb.group({
        profile: [null, Validators.required],
        selectedProfile: [null, Validators.required],
        // selfDisclosure only required if module is booked
        selfDisclosureId: [null, Validators.required],
        noSelfDisclosure: [null, Validators.required]
      }),
      detailedInformation: this.fb.group({
        conversationConfigs: [DEFAULT_CONVERSATION_RESTRICTION_CONFIG],
        name: ['', Validators.required],
        userID: [''],
        writeProtection: ['UNPROTECTED', Validators.required],
        bathRooms: [
          null,
          Validators.compose([Validators.min(0), integerValidator])
        ],
        basementSize: [null, Validators.min(0)],
        buildingCondition: ['NO_INFORMATION'],
        flatType: [null],
        ground: [undefined],
        availableFrom: this.fb.group({
          dateAvailable: [null, isValidDateValidator],
          stringAvailable: ['']
        }),
        constructionYear: [
          undefined,
          Validators.compose([
            Validators.pattern('[0-9]{4}'),
            Validators.max(PropertyFormManager.MAX_CONSTRUCTION_YEAR)
          ])
        ],
        externalResources: this.fb.control([]),
        heatingCost: [null, Validators.compose([Validators.min(0)])],
        otherCosts: [null, Validators.compose([Validators.min(0)])],
        heatingCostIncluded: [false],
        allowRuvDeposit: [null],
        pricesWriteProtected: [false],
        disableEntranceFee: [null],
        heater: [undefined],
        energyCertificate: this.fb.group({
          yearOfConstruction: [
            undefined,
            Validators.compose([
              Validators.pattern('[0-9]{4}'),
              Validators.max(PropertyFormManager.MAX_CONSTRUCTION_YEAR)
            ])
          ],
          primaryEnergyProvider: [undefined],
          energyCertificateType: [undefined, Validators.required],
          primaryEnergyConsumption: [null, Validators.min(0)],
          usageCertificate: this.fb.group({
            energyConsumption: [
              null,
              Validators.compose([Validators.required, Validators.min(0)])
            ],
            includesHeatConsumption: [null],
            energyEfficiencyClass: [null, Validators.required]
          }),
          creationDate: [null, Validators.required],
          demandCertificate: this.fb.group({
            endEnergyConsumption: [
              null,
              Validators.compose([Validators.required, Validators.min(0)])
            ],
            energyEfficiencyClass: [null, Validators.required]
          }),
          austrianEnergyCertificate: this.fb.group({
            dateOfExpiry: [null, isValidDateValidator],
            heatingEnergyDemand: [null],
            heatingEnergyDemandClass: [null],
            overallEnergyEfficiencyFactor: [null],
            overallEnergyEfficiencyFactorClass: [null]
          })
        }),
        guestToilette: [null],

        houseType: [null],
        objectType: [ObjectType.FLAT],
        numberOfFloors: [
          null,
          Validators.compose([Validators.min(0), integerValidator])
        ],
        numberOfBalconies: [null, Validators.min(0)],
        numberOfTerraces: [null, Validators.min(0)],
        numberOfLoggias: [null, Validators.min(0)],
        balconyTerraceArea: [null, Validators.min(0)],
        numberOfBedrooms: [null, Validators.min(0)],
        furnishingType: [null],
        bathroomEquipment: this.fb.group({
          shower: null,
          bathtub: null,
          window: null,
          bidet: null,
          urinal: null
        }),
        washingMachineConnection: null,
        washingMachineConnectionPlaces: null,
        shutter: null,
        wheelchairAccessible: null,
        basementAvailable: null,
        intercomType: [null],
        fireplace: [null],
        gardenUse: [null],
        washDryRoom: [null],
        storeRoom: [null],
        bicycleRoom: [null],
        attic: [null],
        seniors: [null],
        landArea: [null, Validators.min(0)],
        parkingSpaces: this.fb.control([]),
        parkingPriceIncludedToAdditionalCosts: [],
        kitchenette: null,
        garden: null,
        tvSatCable: null,
        barrierFree: null,
        elevator: null,
        historicBuilding: null,
        objectDescription: this.fb.group(
          getMultiLanguageStringFormGroupControlsConfig([
            '',
            Validators.compose([
              Validators.maxLength(3500),
              forbiddenCharactersValidator(
                PropertyFormManager.TEXTAREAS_FORBIDDEN_CHARACTERS
              )
            ])
          ])
        ),
        objectLocationText: this.fb.group(
          getMultiLanguageStringFormGroupControlsConfig([
            '',
            Validators.compose([
              Validators.maxLength(3999),
              forbiddenCharactersValidator(
                PropertyFormManager.TEXTAREAS_FORBIDDEN_CHARACTERS
              )
            ])
          ])
        ),
        furnishingDescription: this.fb.group(
          getMultiLanguageStringFormGroupControlsConfig([
            '',
            Validators.compose([
              Validators.maxLength(3999),
              forbiddenCharactersValidator(
                PropertyFormManager.TEXTAREAS_FORBIDDEN_CHARACTERS
              )
            ])
          ])
        ),
        objectMiscellaneousText: this.fb.group(
          getMultiLanguageStringFormGroupControlsConfig([
            '',
            Validators.compose([
              Validators.maxLength(3999),
              forbiddenCharactersValidator(
                PropertyFormManager.TEXTAREAS_FORBIDDEN_CHARACTERS
              )
            ])
          ])
        ),
        serviceCharge: [null, Validators.compose([Validators.min(0)])],
        floor: [
          null,
          Validators.compose([Validators.min(-3), integerValidator])
        ],
        bailment: [null, Validators.compose([Validators.min(0)])],
        basePrice: [
          null,
          Validators.compose([Validators.required, Validators.min(0)])
        ],
        salesData: this.fb.group({
          price: [
            { value: null, disabled: true },
            Validators.compose([Validators.required, Validators.min(0)])
          ],
          serviceCharge: [null]
        }),
        courtageData: this.fb.group({
          courtageInPercent: [false],
          courtage: [null],
          plusVAT: [false],
          text: ['']
        }),
        totalRentGross: [null, Validators.min(0)],
        totalRentGrossManuallySet: false,
        entryPrice: null,
        rooms: [
          null,
          Validators.compose([Validators.required, Validators.min(0)])
        ],
        halfRooms: null,
        size: null,
        garageData: this.fb.group({
          garageType: 'NO_INFORMATION',
          freeUntil: null,
          length: [null, Validators.compose([Validators.min(0)])],
          width: [null, Validators.compose([Validators.min(0)])],
          height: [null, Validators.compose([Validators.min(0)])]
        }),
        commercialData: this.fb.group({
          commercialType: [
            { value: null, disabled: true },
            Validators.required
          ],
          commercialSubType: [
            { value: null, disabled: true },
            Validators.required
          ],
          pricePerSquareMeter: [0, Validators.compose([Validators.min(0)])],
          hasCanteen: null,
          lanCables: null,
          highVoltage: null,
          airConditioning: null,
          distanceToTrainStation: [
            null,
            Validators.compose([Validators.min(0)])
          ],
          distanceToHighway: [null, Validators.compose([Validators.min(0)])],
          distanceToPublicTransport: [
            null,
            Validators.compose([Validators.min(0)])
          ],
          distanceToAirport: [null, Validators.compose([Validators.min(0)])],
          vatNotIncludedInPrice: false
        }),
        customerDefinedFieldValues: this.fb.control([])
      }),
      newPropertyDocuments: this.fb.group({
        documents: [[]],
        coverImage: [null],
        floorPlan: [null],
        imagesAndVideos: [[]],
        youtubeLink: [''],
        expose: [null]
      }),
      newPropertyPortals: this.fb.group({
        automatedRentalTemplate: [],
        automatedRentalProcess: new FormControl(),
        portals: [[]],
        credentials: [[]],
        homepages: [[]],
        autoDeactivateEnabled: [false],
        autoDeactivateConfig: this.fb.group({
          type: [null],
          amount: [null],
          applicationScoreThreshold: [10]
        }),
        autoActivateEnabled: [false],
        autoActivateConfig: this.fb.group({
          date: [null, isValidDateValidator],
          time: [null, Validators.pattern(RegexTypes.TIME)],
          activationDate: [null]
        })
      })
    });

    this.synchronizeControls(
      this.basicInformationForm,
      this.detailedInformationForm,
      'rooms'
    );
    this.synchronizeControls(
      this.basicInformationForm,
      this.detailedInformationForm,
      'halfRooms'
    );
    this.synchronizeControls(
      this.basicInformationForm,
      this.detailedInformationForm,
      'size'
    );
    this.synchronizeControls(
      this.basicInformationForm,
      this.detailedInformationForm,
      'basePrice'
    );
    this.synchronizeControls(
      this.basicInformationForm.get('salesData'),
      this.detailedInformationForm.get('salesData'),
      'price'
    );
    this.synchronizeControls(
      this.basicInformationForm,
      this.newPropertyDocumentsForm,
      'coverImage'
    );
    this.synchronizeControls(
      this.basicInformationForm,
      this.newPropertyDocumentsForm,
      'floorPlan'
    );

    this.handleEnergyCertificateChanges(this.energyCertificateForm.value);
    this.handleEnergyCertificateChanges(this.objectTypeControl.value);

    this.energyCertificateForm
      .get('energyCertificateType')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.energyCertificateForm.get('creationDate').reset();
      });

    this.objectTypeControl.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => this.handleObjectTypeChanges(value));

    this.energyCertificateForm
      .get('energyCertificateType')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(energyCertificateType =>
        this.handleEnergyCertificateChanges({ energyCertificateType })
      );

    this.energyCertificateForm
      .get('creationDate')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(creationDate =>
        this.handleEnergyCertificateChanges({ creationDate })
      );
    this.previousTenantAppointmentEnabled.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.toggleValidity(this.previousTenant.get('email'), value);
        this.toggleValidity(this.previousTenant.get('phone'), value);
        this.toggleValidity(this.previousTenant.get('name'), value);
        this.toggleValidity(this.previousTenant.get('firstname'), value);
      });

    this.marketingType.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => {
        switch (value) {
          case MarketingType.SALES: {
            this.basicInformationForm.get('salesData.price').enable();
            this.detailedInformationForm.get('salesData.price').enable();
            this.basicInformationForm.get('basePrice').disable();
            this.detailedInformationForm.get('basePrice').disable();
            this.desiredTenantForm.get('selfDisclosureId').disable();
            this.desiredTenantForm.get('noSelfDisclosure').disable();
            break;
          }
          case MarketingType.RENT: {
            this.basicInformationForm.get('salesData.price').disable();
            this.detailedInformationForm.get('salesData.price').disable();
            this.basicInformationForm.get('basePrice').enable();
            this.detailedInformationForm.get('basePrice').enable();
            this.desiredTenantForm.get('selfDisclosureId').enable();
            this.desiredTenantForm.get('noSelfDisclosure').enable();
            break;
          }
        }
      });

    this.addressForm
      .get('country')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: CustomerLocation) =>
        this.modifyEnergyCertificateFormByCustomerLocation(value)
      );

    this.detailedInformationForm
      .get('courtageData.courtage')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => {
        if (!value) {
          this.detailedInformationForm
            .get('courtageData.plusVAT')
            .patchValue(false);
        }
      });

    this.propertyType.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => {
        // Everytime the propertytype changes, the energy certificate fields should be cleared
        // When they still have data, then the validations will not be correct
        this.energyCertificateForm.reset();

        switch (value) {
          case PropertyType.FLAT: {
            this.propertySizeBasis.setValidators(
              Validators.compose([Validators.required, Validators.min(1)])
            );
            this.propertySizeDetail.setValidators(
              Validators.compose([Validators.required, Validators.min(1)])
            );
            this.energyCertificateForm.enable();
            if (this.addressForm.get('country').value === CustomerLocation.DE) {
              this.energyCertificateForm
                .get('usageCertificate.energyEfficiencyClass')
                .setValidators(Validators.required);
              this.energyCertificateForm
                .get('usageCertificate.energyConsumption')
                .setValidators(Validators.required);
              this.energyCertificateForm
                .get('demandCertificate.energyEfficiencyClass')
                .setValidators(Validators.required);
              this.energyCertificateForm
                .get('demandCertificate.endEnergyConsumption')
                .setValidators(Validators.required);
            }

            this.basicInformationForm.get('rooms').enable();
            this.basicInformationForm.get('halfRooms').enable();
            this.detailedInformationForm.get('halfRooms').enable();
            this.detailedInformationForm.get('objectType').enable();
            this.basicInformationForm.get('previousTenant').enable();
            this.detailedInformationForm.get('rooms').enable();
            this.detailedInformationForm.get('garageData').disable();
            this.detailedInformationForm.get('commercialData').disable();
            this.detailedInformationForm.get('courtageData').enable();
            this.detailedInformationForm
              .get('objectType')
              .patchValue(value, { emitEvent: false });
            break;
          }

          case PropertyType.GARAGE: {
            this.propertySizeBasis.clearValidators();
            this.propertySizeDetail.clearValidators();
            this.basicInformationForm.get('rooms').disable();
            this.basicInformationForm.get('halfRooms').disable();
            this.detailedInformationForm.get('halfRooms').disable();
            this.detailedInformationForm.get('objectType').disable();
            this.energyCertificateForm.disable();
            this.detailedInformationForm.get('rooms').disable();
            this.detailedInformationForm.get('garageData').enable();
            this.detailedInformationForm.get('commercialData').disable();
            this.detailedInformationForm
              .get('objectType')
              .patchValue(value, { emitEvent: false });
            this.energyCertificateForm.disable();
            break;
          }

          case PropertyType.COMMERCIAL: {
            this.propertySizeBasis.setValidators(
              Validators.compose([Validators.required, Validators.min(1)])
            );
            this.propertySizeDetail.setValidators(
              Validators.compose([Validators.required, Validators.min(1)])
            );
            this.energyCertificateForm.enable();
            this.energyCertificateForm
              .get('usageCertificate.energyEfficiencyClass')
              .clearValidators();
            this.energyCertificateForm
              .get('usageCertificate.energyConsumption')
              .clearValidators();
            this.energyCertificateForm
              .get('demandCertificate.energyEfficiencyClass')
              .clearValidators();
            this.energyCertificateForm
              .get('demandCertificate.endEnergyConsumption')
              .clearValidators();
            this.basicInformationForm.get('rooms').disable();
            this.basicInformationForm.get('halfRooms').disable();
            this.detailedInformationForm.get('halfRooms').disable();
            this.detailedInformationForm.get('objectType').disable();
            this.basicInformationForm.get('previousTenant').enable();
            this.detailedInformationForm.get('rooms').disable();
            this.detailedInformationForm.get('garageData').disable();
            this.detailedInformationForm.get('commercialData').enable();
            this.detailedInformationForm.get('courtageData').enable();
            this.detailedInformationForm
              .get('objectType')
              .patchValue('OFFICE', { emitEvent: false });
            break;
          }
        }
      });
  }

  private modifyEnergyCertificateFormByCustomerLocation(
    value: CustomerLocation
  ) {
    switch (value) {
      case CustomerLocation.DE: {
        if (this.propertyType.value === PropertyType.FLAT) {
          this.energyCertificateForm
            .get('usageCertificate.energyEfficiencyClass')
            .setValidators(Validators.required);
          this.energyCertificateForm
            .get('usageCertificate.energyConsumption')
            .setValidators(Validators.required);
          this.energyCertificateForm
            .get('demandCertificate.energyEfficiencyClass')
            .setValidators(Validators.required);
          this.energyCertificateForm
            .get('demandCertificate.endEnergyConsumption')
            .setValidators(Validators.required);
        }

        this.energyCertificateForm
          .get('creationDate')
          .setValidators(Validators.required);
        this.energyCertificateForm
          .get('energyCertificateType')
          .setValidators(Validators.required);

        this.energyCertificateForm
          .get('austrianEnergyCertificate.heatingEnergyDemand')
          .clearValidators();
        this.energyCertificateForm
          .get('austrianEnergyCertificate.heatingEnergyDemandClass')
          .clearValidators();
        this.energyCertificateForm
          .get('austrianEnergyCertificate.overallEnergyEfficiencyFactor')
          .clearValidators();
        this.energyCertificateForm
          .get('austrianEnergyCertificate.overallEnergyEfficiencyFactorClass')
          .clearValidators();
        break;
      }
      case CustomerLocation.AT: {
        this.energyCertificateForm
          .get('usageCertificate.energyEfficiencyClass')
          .clearValidators();
        this.energyCertificateForm
          .get('usageCertificate.energyConsumption')
          .clearValidators();
        this.energyCertificateForm
          .get('demandCertificate.energyEfficiencyClass')
          .clearValidators();
        this.energyCertificateForm
          .get('demandCertificate.endEnergyConsumption')
          .clearValidators();

        this.energyCertificateForm.get('creationDate').clearValidators();
        this.energyCertificateForm
          .get('energyCertificateType')
          .clearValidators();

        this.energyCertificateForm
          .get('austrianEnergyCertificate.heatingEnergyDemand')
          .setValidators(Validators.required);
        this.energyCertificateForm
          .get('austrianEnergyCertificate.heatingEnergyDemandClass')
          .setValidators(Validators.required);
        this.energyCertificateForm
          .get('austrianEnergyCertificate.overallEnergyEfficiencyFactor')
          .setValidators(Validators.required);
        this.energyCertificateForm
          .get('austrianEnergyCertificate.overallEnergyEfficiencyFactorClass')
          .setValidators(Validators.required);
      }
    }
  }

  public desiredTenantPatchWbs(wbsValue: boolean) {
    const selectedProfile: Prioset =
      this.desiredTenantForm.get('selectedProfile').value;
    const conditions: PriosetCondition[] =
      selectedProfile.data.conditions || [];
    this.desiredTenantForm.get('selectedProfile').patchValue(
      {
        ...selectedProfile,
        data: {
          ...selectedProfile.data,
          conditions: conditions.map(condition => {
            if (condition.data.conditionId === ConditionId.HOUSING_PERMISSION) {
              return {
                ...condition,
                value: wbsValue,
                knockout: true
              };
            }
            return condition;
          })
        }
      },
      { emitEvent: false }
    );
  }

  public basicInformationPatchWbs(wbsValue: boolean) {
    this.basicInformationForm.get('wbs').patchValue(
      {
        value: wbsValue,
        knockout: wbsValue
      },
      { emitEvent: false }
    );
  }

  public objectDescriptionControl(
    languageCode: AvailableLanguageCodesEnum
  ): AbstractControl {
    return this.detailedInformationForm
      .get('objectDescription')
      ?.get(languageCode);
  }

  public objectLocationTextControl(
    languageCode: AvailableLanguageCodesEnum
  ): AbstractControl {
    return this.detailedInformationForm
      .get('objectLocationText')
      ?.get(languageCode);
  }

  public objectMiscellaneousTextControl(
    languageCode: AvailableLanguageCodesEnum
  ): AbstractControl {
    return this.detailedInformationForm
      .get('objectMiscellaneousText')
      ?.get(languageCode);
  }

  public furnishingDescriptionControl(
    languageCode: AvailableLanguageCodesEnum
  ): AbstractControl {
    return this.detailedInformationForm
      .get('furnishingDescription')
      ?.get(languageCode);
  }

  private synchronizeControls(
    source: AbstractControl,
    target: AbstractControl,
    controlName: string
  ) {
    source
      .get(controlName)
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => target.get(controlName).patchValue(value));

    target
      .get(controlName)
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => source.get(controlName).patchValue(value));
  }

  private handleObjectTypeChanges(value: string) {
    if (value !== ObjectType.HOUSE) {
      this.detailedInformationForm.get('houseType').reset();
      this.detailedInformationForm.get('landArea').reset();
    }

    if (value !== ObjectType.FLAT) {
      this.detailedInformationForm.get('flatType').reset();
    }
  }

  private handleEnergyCertificateChanges(singleValue: {
    [key: string]: string;
  }) {
    if (isPropertyTypeGarage(this.propertyType.value)) return;
    const value = { ...this.energyCertificateForm.value, ...singleValue };

    const usageForm = this.energyCertificateForm.get(
      'usageCertificate'
    ) as FormGroup;
    const demandForm = this.energyCertificateForm.get(
      'demandCertificate'
    ) as FormGroup;

    const type = value.energyCertificateType;
    const usageSelected = type === EnergyCertificateType.USAGE_IDENTIFICATION;
    const demandSelected = type === EnergyCertificateType.DEMAND_IDENTIFICATION;
    const notAvailableSelected = type === EnergyCertificateType.NO_AVAILABLE;
    const efficiencyClassRequired =
      value.creationDate === CertificateCreationDates.MAY_2014;
    const notNecessary =
      value.creationDate === CertificateCreationDates.NOT_NECESSARY;
    const without = value.creationDate === CertificateCreationDates.WITHOUT;

    this.toggleDisableState(
      usageForm.controls['energyConsumption'],
      !type || !usageSelected || notNecessary || without
    );
    this.toggleDisableState(
      demandForm.controls['endEnergyConsumption'],
      !type || !demandSelected || notNecessary || without
    );
    this.toggleDisableState(
      this.energyCertificateForm.controls['primaryEnergyConsumption'],
      !type || notAvailableSelected || notNecessary || without
    );

    this.toggleDisableState(
      usageForm.controls['energyEfficiencyClass'],
      !type ||
        !usageSelected ||
        !efficiencyClassRequired ||
        notNecessary ||
        without
    );

    this.toggleDisableState(
      demandForm.controls['energyEfficiencyClass'],
      !type ||
        !demandSelected ||
        !efficiencyClassRequired ||
        notNecessary ||
        without
    );

    this.toggleValidity(
      this.energyCertificateForm.get('primaryEnergyProvider'),
      efficiencyClassRequired
    );
  }

  private toggleDisableState(control: AbstractControl, disable: boolean) {
    return disable ? control.disable() : control.enable();
  }

  private toggleValidity(
    control: AbstractControl,
    required: boolean,
    onlySelf = false
  ) {
    if (required) {
      control.addValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('^\\S.*[a-zA-Z\\s]*$')
        ])
      );
    } else {
      control.clearValidators();
    }

    control.updateValueAndValidity({ onlySelf });
  }

  private mappedCustomerDefinedFieldValues(value: CustomerDefinedFieldValue[]) {
    return value?.map(({ definition, value }) => ({
      fieldDefinitionId: definition.id,
      label: definition.label,
      type: definition.type,
      value: value.value
    }));
  }
}
