<div class="self-disclosure-person__content">
  <form [formGroup]="form">
    <div class="d-flex flex-row mb10" [ngClass]="{ mt10: showRemove }">
      <span>{{ this.title | translate }}</span>
      @if (showRemove) {
        <div class="remove-attachment-button">
          <svg-icon
            src="/assets/images/icons/icon-bin.svg"
            [applyClass]="true"
            [svgStyle]="{ 'width.px': 15 }"
            class="secondary-svg-color path rect"
            (click)="removeHandler()"
          ></svg-icon>
        </div>
      }
    </div>

    <!-- Display salutation type subquestion -->
    @for (
      question of getSubQuestionsFormControl.controls;
      track question;
      let i = $index
    ) {
      <div>
        @if (showSelectTypeQuestion(selfDisclosureQuestion.subQuestions[i])) {
          <div
            [formGroup]="getQuestionsForm(i)"
            class="col-12 col-lg-6 col-md-6 pl0 mb16"
          >
            <app-form-field>
              <app-form-field-label
                >{{ 'SALUTE_L' | translate }}
              </app-form-field-label>
              <app-select
                [required]="selfDisclosureQuestion.subQuestions[i].mandatory"
                [items]="
                  getSelectTypeItems(
                    selfDisclosureQuestion.subQuestions[i].constantName
                  )
                "
                [placeholder]="'general.select_l' | translate"
                formControlName="answer"
              >
              </app-select>
            </app-form-field>
          </div>
        }
      </div>
    }

    <div [formGroup]="getAnswerFormControl">
      <div class="row mb5">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.first_name_l' | translate }}
            </app-form-field-label>
            <input
              appInput
              type="text"
              [placeholder]="'forms.type_firstname' | translate"
              formControlName="firstName"
              [required]="selfDisclosureQuestion.mandatory"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.last_name_l' | translate }}
            </app-form-field-label>
            <input
              appInput
              type="text"
              [required]="selfDisclosureQuestion.mandatory"
              [placeholder]="'forms.type_lastname' | translate"
              formControlName="lastName"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>

      <div class="row mb5">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.birthname_l' | translate }}
            </app-form-field-label>
            <input
              appInput
              type="text"
              [placeholder]="'forms.type_birthname' | translate"
              formControlName="birthName"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.birthplace_l' | translate }}
            </app-form-field-label>
            <input
              appInput
              type="text"
              [required]="selfDisclosureQuestion.mandatory"
              [placeholder]="'forms.type_birthplace' | translate"
              formControlName="birthPlace"
              class="form-control"
            />
          </app-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.birthdate_l' | translate }}
            </app-form-field-label>
            <app-date
              [required]="selfDisclosureQuestion.mandatory"
              formControlName="birthDate"
              [startDate]="startDateStruct"
              [maxDate]="maxDateStruct"
              [minDate]="minDateStruct"
            ></app-date>
          </app-form-field>
        </div>
      </div>

      <!-- display all non-salutation subQuestion -->
      <div>
        @for (
          question of getSubQuestionsFormControl.controls;
          track question;
          let i = $index
        ) {
          <div>
            @if (isOtherSubQuestion(selfDisclosureQuestion.subQuestions[i])) {
              <ng-container [formGroup]="getQuestionsForm(i)" class="mb16">
                @if (
                  selfDisclosureQuestion.subQuestions[i]
                    .showSelfDisclosureQuestions &&
                  isTypeBooleanOrSelect(selfDisclosureQuestion.subQuestions[i])
                ) {
                  <div class="mt20">
                    <app-form-field>
                      <app-form-field-label
                        >{{
                          selfDisclosureQuestion.subQuestions[i].title
                            | translate
                        }}
                      </app-form-field-label>
                      <app-flat-select
                        formControlName="answer"
                        [items]="
                          options(selfDisclosureQuestion.subQuestions[i])
                        "
                        [required]="
                          selfDisclosureQuestion.subQuestions[i].mandatory
                        "
                        [multiple]="false"
                      ></app-flat-select>
                    </app-form-field>
                    @if (showHint(i)) {
                      <div>
                        <app-form-field>
                          <app-form-field-label
                            >{{
                              selfDisclosureQuestion.subQuestions[i].commentHint
                                | translate
                            }}
                          </app-form-field-label>
                          <textarea
                            appInput
                            count="true"
                            maxValue="1000"
                            formControlName="comment"
                            class="form-control"
                          ></textarea>
                        </app-form-field>
                      </div>
                    }
                  </div>
                }
                @if (
                  selfDisclosureQuestion.subQuestions[i]
                    .showSelfDisclosureQuestions &&
                  (selfDisclosureQuestion.subQuestions[i].type ===
                    SelfDisclosureQuestionType.IBAN ||
                    selfDisclosureQuestion.subQuestions[i].type ===
                      SelfDisclosureQuestionType.TAX_ID)
                ) {
                  <div class="mt20">
                    <app-form-field>
                      <app-form-field-label
                        >{{
                          selfDisclosureQuestion.subQuestions[i].title
                            | translate
                        }}
                      </app-form-field-label>
                      <input
                        appInput
                        type="text"
                        [required]="
                          selfDisclosureQuestion.subQuestions[i].mandatory
                        "
                        [placeholder]="
                          selfDisclosureQuestion.subQuestions[i].title +
                            '_HINT_L' | translate
                        "
                        formControlName="answer"
                        class="form-control"
                      />
                    </app-form-field>
                  </div>
                }
              </ng-container>
            }
            <div>
              @if (
                selfDisclosureQuestion.subQuestions[i]
                  .showSelfDisclosureQuestions &&
                isEmployment(selfDisclosureQuestion.subQuestions[i])
              ) {
                <div class="mt20">
                  <app-self-disclosure-employment
                    [form]="getSubQuestionForm(i)"
                    [selfDisclosureQuestion]="
                      selfDisclosureQuestion.subQuestions[i]
                    "
                  ></app-self-disclosure-employment>
                </div>
              }
              @if (
                selfDisclosureQuestion.subQuestions[i]
                  .showSelfDisclosureQuestions &&
                isAddress(selfDisclosureQuestion.subQuestions[i])
              ) {
                <div class="mt20">
                  <app-self-disclosure-address
                    [form]="getSubQuestionForm(i)"
                    [selfDisclosureQuestion]="
                      selfDisclosureQuestion.subQuestions[i]
                    "
                  ></app-self-disclosure-address>
                </div>
              }
              @if (
                selfDisclosureQuestion.subQuestions[i]
                  .showSelfDisclosureQuestions &&
                isDocument(selfDisclosureQuestion.subQuestions[i])
              ) {
                <div class="mt20">
                  <app-self-disclosure-document
                    [form]="getSubQuestionForm(i)"
                    [selfDisclosureQuestion]="
                      selfDisclosureQuestion.subQuestions[i]
                    "
                  ></app-self-disclosure-document>
                </div>
              }
            </div>
          </div>
        }
      </div>

      @if (
        getConfirmationsFormControl &&
        getConfirmationsFormControl.controls.length > 0
      ) {
        <div class="mt20 mb20">
          <div class="d-flex flex-row mb10">
            {{ 'self_disclosure.answers.confirm_l' | translate }}
            <span class="required-marker ng-star-inserted">&nbsp;*</span>
          </div>
          @for (
            confirm of getConfirmationsFormControl.controls;
            track confirm;
            let i = $index
          ) {
            <div>
              <div
                [formGroup]="confirm"
                [ngClass]="{ invalid: confirm.invalid && confirm.touched }"
              >
                <app-form-field>
                  <app-checkbox
                    required
                    [label]="confirm.value.text | translate"
                    formControlName="checked"
                  ></app-checkbox>
                </app-form-field>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </form>
</div>
