import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  inject
} from '@angular/core';
import { PropertyPortalStates } from '@ui/shared/models';

const stateToLabelMapping = {
  [PropertyPortalStates.ACTIVE]: 'success',
  [PropertyPortalStates.ERROR]: 'error',
  [PropertyPortalStates.PENDING]: 'warning',
  [PropertyPortalStates.DEACTIVATED]: 'default'
};

@Directive({
  selector: '[appPortalStatusClass]',
  standalone: true
})
export class PortalStatusClassDirective implements OnChanges {
  private renderer = inject(Renderer2);
  private el = inject(ElementRef);

  @Input() status: string;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.status.firstChange) {
      this.renderer.removeClass(
        this.el.nativeElement,
        stateToLabelMapping[changes.status.previousValue]
      );
    }
    this.renderer.addClass(
      this.el.nativeElement,
      stateToLabelMapping[changes.status.currentValue]
    );
  }
}
