import { Injectable, inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {
  Attachment,
  CertificateCreationDates,
  CustomerDefinedFieldValuesForm,
  CustomerDefinedValuesPayload,
  EnergyCertificate,
  EnergyCertificateType,
  LandlordUser,
  Prioset
} from '@ui/shared/models';
import { DateTimeService } from 'libs/services';
import { isRentalObject, stripTypenameProperty } from 'libs/utils';

import { presetProfilesIds } from 'libs/config';
import {
  DesiredTenantFormModel,
  FinalPropertyPayload,
  PropertyUpdateData
} from 'libs/properties';

@Injectable()
export class PropertyPayloadFactory {
  private translateService = inject(TranslateService);
  private dateTimeService = inject(DateTimeService);

  public getPayloadToSave(
    data: PropertyUpdateData,
    userData?: LandlordUser
  ): FinalPropertyPayload {
    const {
      coverImage,
      floorPlan,
      externalId,
      type,
      administrationUnitId,
      externalIdPart,
      propertyManagerId,
      propertyManagerName,
      wbs,
      marketingType,
      showLegalTextAndCheckboxes,
      districtId,
      ...basicInformation
    } = data.model.basicInformation;
    const {
      selectedProfile: { template, ...selectedProfileWithoutTemplate } = {},
      selfDisclosureId
    } = data.model.desiredTenant || ({} as DesiredTenantFormModel);
    const { documents, imagesAndVideos, expose } =
      data.model.newPropertyDocuments;
    const {
      conversationConfigs,
      allowRuvDeposit,
      totalRentGross,
      totalRentGrossManuallySet,
      customerDefinedFieldValues,
      disableEntranceFee,
      ...detailedInformation
    } = data.model.detailedInformation;
    const {
      credentials,
      homepages,
      autoDeactivateEnabled,
      autoDeactivateConfig,
      autoActivateEnabled,
      autoActivateConfig
    } = data.model.newPropertyPortals;

    const { referenceId, version } = data;

    const attachments = coverImage
      ? [coverImage, ...(imagesAndVideos || [])]
      : [...(imagesAndVideos || [])];

    let documentsWithFloorPlan: any[];
    if (floorPlan) {
      // We search for the floorPlan by .find() and need the floorPlan at beginning.
      // If multiple floorPlans exist, we'd have a different one in the floorPlan section
      // after each save (round robin like)
      documentsWithFloorPlan = [floorPlan, ...documents];
    } else {
      documentsWithFloorPlan = documents;
    }

    const energyCertificate = this.getEnergyCertifitatePayload(
      detailedInformation.energyCertificate
    );
    const credentialIds = credentials?.map(credential => credential?.id);
    const homepageModuleIds = homepages?.map(homepage => homepage?.id);
    const prioset = isRentalObject(marketingType)
      ? this.getPriosetPayload(selectedProfileWithoutTemplate)
      : undefined;

    const propertyData = {
      ...basicInformation,
      ...detailedInformation,
      totalRentGross,
      totalRentGrossManuallySet: !!totalRentGross,
      energyCertificate,
      attachments: this.getAttachmentsPayload(
        attachments,
        'picture',
        detailedInformation.name
      ),
      documents: this.getAttachmentsPayload(
        documentsWithFloorPlan,
        'document',
        detailedInformation.name
      ),
      expose,
      referenceId
    };

    let payload = {
      conversationConfigs: stripTypenameProperty(conversationConfigs) || {},
      allowRuvDeposit,
      data: propertyData,
      propertyManagerId,
      disableEntranceFee,
      autoDeactivateConfig: autoDeactivateEnabled ? autoDeactivateConfig : null,
      autoActivateConfig: autoActivateEnabled
        ? {
            activationDate: this.dateTimeService.getFullDateAsISOString(
              autoActivateConfig.date,
              autoActivateConfig.time
            )
          }
        : null,
      externalId: externalId || null,
      administrationUnitId: administrationUnitId || null,
      externalIdPart: externalIdPart,
      type,
      prioset,
      selfDisclosureId: isRentalObject(marketingType) ? selfDisclosureId : null,
      credentialIds,
      homepageModuleIds,
      version,
      customerDefinedFieldValues: this.getCustomerDefinedValuesPayload(
        customerDefinedFieldValues
      ),
      marketingType,
      showLegalTextAndCheckboxes,
      districtId
    } as FinalPropertyPayload;

    if (data.propertyId) payload.propertyID = data.propertyId;

    if (!data.propertyId) {
      payload = {
        ...payload,
        customerID: userData.customer.id,
        userID: userData.id
      } as FinalPropertyPayload;
    }

    return payload;
  }

  private getAttachmentsPayload(
    attachments: Attachment[],
    type: 'picture' | 'document',
    propertyName: string
  ) {
    const translatedType = this.translateService.instant(
      type === 'picture' ? 'PICTURE_L' : 'DOCUMENT_L'
    ) as string;
    return attachments.map((attachment, index) => ({
      ...attachment,
      index,
      title:
        attachment.title ||
        attachment.name ||
        `${propertyName} ${translatedType}${index + 1}.${attachment.extension}`
    }));
  }

  private getEnergyCertifitatePayload(energyCertificate: EnergyCertificate) {
    const payload: EnergyCertificate = { ...energyCertificate };

    if (payload.creationDate !== CertificateCreationDates.MAY_2014) {
      payload.demandCertificate = {
        ...payload.demandCertificate,
        energyEfficiencyClass: null
      };
      payload.usageCertificate = {
        ...payload.usageCertificate,
        energyEfficiencyClass: null
      };
    }

    if (payload.creationDate !== CertificateCreationDates.APRIL_2014) {
      payload.usageCertificate = {
        ...payload.usageCertificate,
        includesHeatConsumption: null
      };
    }

    if (
      payload.energyCertificateType !==
      EnergyCertificateType.DEMAND_IDENTIFICATION
    ) {
      payload.demandCertificate = null;
    }

    if (
      payload.energyCertificateType !==
      EnergyCertificateType.USAGE_IDENTIFICATION
    ) {
      payload.usageCertificate = null;
    }

    if (payload.creationDate === CertificateCreationDates.NOT_NECESSARY) {
      payload.usageCertificate = null;
      payload.demandCertificate = null;
    }

    return payload;
  }

  private getPriosetPayload(selectedProfileWithoutTemplate: Prioset) {
    const { id, ...restSelectedProfileWithoutTemplate } =
      selectedProfileWithoutTemplate;
    const conditions = restSelectedProfileWithoutTemplate.data.conditions
      ?.filter(condition => condition.value)
      ?.map(({ value, ...rest }) => rest);

    const prioset: Prioset & { basePriosetId?: string } = {
      ...restSelectedProfileWithoutTemplate,
      data: {
        ...restSelectedProfileWithoutTemplate.data,
        conditions
      }
    };

    if (!presetProfilesIds.includes(id)) {
      prioset.basePriosetId = selectedProfileWithoutTemplate.id;
      prioset.id = id;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return prioset;
  }

  private getCustomerDefinedValuesPayload(
    values: CustomerDefinedFieldValuesForm[]
  ): CustomerDefinedValuesPayload[] {
    return values.map(({ fieldDefinitionId, value, type }) => ({
      fieldDefinitionId,
      value: {
        value,
        type
      }
    }));
  }
}
