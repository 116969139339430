import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';

import moment from 'moment';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  PaymentMethod,
  PaymentMethodType,
  Product,
  SubscriptionPeriod
} from '@ui/shared/models';
import { isValueNullOrUndefined } from 'libs/utils';
import { ADDON_DISCOUNT_CONFIG } from 'libs/products/products-module.config';
import { ContextMenuTooltipPlacementEnum } from 'libs/components/molecules/context-menu/context-menu.enum';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  AppInputDirective,
  BadgeComponent,
  CardComponent,
  ContextMenuComponent,
  ContextMenuItemComponent,
  DateComponent,
  FormFieldComponent,
  FormFieldLabelComponent
} from 'libs/components';
import { DateTimePipe } from '../../../pipes/date-time.pipe';

@Component({
  selector: 'app-payment-details-lib',
  templateUrl: './payment-details-lib.component.html',
  styleUrls: ['./payment-details-lib.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentDetailsLibComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    SvgIconComponent,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    CurrencyPipe,
    TranslateModule,
    DateTimePipe,
    BadgeComponent,
    CardComponent,
    ContextMenuComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective,
    DateComponent,
    ContextMenuItemComponent
  ]
})
export class PaymentDetailsLibComponent implements ControlValueAccessor {
  @Input() isAdmin: boolean;
  @Input() products: Product[];
  @Input() totalPrice: number;
  @Input() totalPriceNet: number;
  @Input() totalPricePreDiscount: number;
  @Input() totalPriceNetPreDiscount: number;
  @Input() totalNextPrice: number;
  @Input() totalNextPriceNet: number;
  @Input() totalNextPricePreDiscount: number;
  @Input() totalNextPriceNetPreDiscount: number;
  @Input() currency: string;
  @Input() recalculatingPrice = false;
  @Input() isYearlySelected = false;
  @Input() isNextYearlySelected = false;
  @Input() paymentMethod: PaymentMethod;
  @Input() form: FormGroup;
  @Input() nextProductSubscriptionPeriod: SubscriptionPeriod;
  @Input() currentSubscriptionPeriod: SubscriptionPeriod;
  @Input() renewDate: string;
  @Input() hasActiveProduct: boolean;
  @Input() isTrial: boolean;
  @Output() productChange = new EventEmitter<string>();

  public readonly ADDON_DISCOUNT_CONFIG = ADDON_DISCOUNT_CONFIG;

  ContextMenuTooltipPlacementEnum = ContextMenuTooltipPlacementEnum;
  public value: Product;
  public yearlyDiscount = {
    value: '17%'
  };

  public minDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  /* eslint-disable @typescript-eslint/ban-types */
  private onChange: Function;
  private onTouch: Function;
  /* eslint-enable @typescript-eslint/ban-types */

  readonly periodsMap = {
    [SubscriptionPeriod.MONTHLY]: 'general.monthly_l',
    [SubscriptionPeriod.YEARLY]: 'general.yearly_l'
  };

  get discountEndDate(): string {
    return this.form?.get('discountEnd')?.value as string;
  }

  get isDisabled() {
    if (
      isValueNullOrUndefined(this.discountEndDate) ||
      this.discountEndDate?.length === 0
    ) {
      return true;
    }
    if (this.discountEndDate?.length > 0) {
      return false;
    }
  }

  get isInvoice() {
    return (
      this.paymentMethod &&
      this.paymentMethod.type === PaymentMethodType.INVOICE
    );
  }

  get isMultiplierReadonly() {
    return this.hasActiveProduct;
  }

  get isTrialPeriodRequired() {
    return this.hasActiveProduct && this.isTrial;
  }

  get isTrialPeriodReadOnly() {
    return !this.isTrial;
  }

  onSelectProduct(product: Product) {
    this.productChange.emit(product.id);
  }

  writeValue(value: Product) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }
}
