import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { PortalsModule } from 'libs/portals';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PortalsModule
  ]
})
export class PropertiesModule {
  public static forRoot(): ModuleWithProviders<PropertiesModule> {
    return {
      ngModule: PropertiesModule
    };
  }
}
