import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatSlider,
  MatSliderDragEvent,
  MatSliderRangeThumb
} from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { SearchProfileService } from '../../../../services';
import { FormFieldLabelComponent } from '../../form/form-field/form-field-label/form-field-label.component';
import { SliderComponent } from '../../form/controls/slider/slider.component';
import { FormFieldComponent } from '../../form/form-field/form-field.component';

@Component({
  selector: 'app-search-profile-price-space-rooms-slider',
  standalone: true,
  imports: [
    CommonModule,
    FormFieldLabelComponent,
    FormsModule,
    MatSlider,
    MatSliderRangeThumb,
    TranslateModule,
    ReactiveFormsModule,
    FormFieldComponent,
    SliderComponent,
    SvgIconComponent
  ],
  templateUrl: './search-profile-price-space-rooms-slider.component.html',
  styleUrl: './search-profile-price-space-rooms-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchProfilePriceSpaceRoomsSliderComponent {
  form = input.required<FormGroup>();
  isFlat = input<boolean>();
  isCommercial = input<boolean>();
  isSalesObject = input.required<boolean>();

  maxUpperBoundRent = input<number>();
  minUpperBoundRent = input<number>();
  stepsForUpperBoundRent = input<number>();

  public sp = inject(SearchProfileService);
  public upperBoundSizeMax = this.sp.getUpperBoundSize();
  public upperBoundRoomsMax = this.sp.getUpperBoundRooms();

  public displayRoomsLabel(value: number) {
    return value >= this.upperBoundRoomsMax ? `${value}+` : `${value}`;
  }

  public displaySizeLabel(value: number) {
    return value >= this.upperBoundSizeMax ? `${value}+` : `${value}`;
  }

  /**
   * When the user stops dragging and leaves the nobs with the cursor,
   * the number bubbles should be hidden
   * @param event
   */
  dragEnd(event: MatSliderDragEvent) {
    event.source._isFocused = false;
  }
}
