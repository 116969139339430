import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { FormsModule, NgControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  Addon,
  AddonDiscount,
  AddonPriceType,
  CalculatedPrices
} from '@ui/shared/models';
import { ADDON_DISCOUNT_CONFIG } from 'libs/products/products-module.config';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgClass } from '@angular/common';
import {
  AppInputDirective,
  BadgeComponent,
  CheckComponent,
  DateComponent
} from 'libs/components';
import { DateTimePipe } from '../../../pipes/date-time.pipe';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    CurrencyPipe,
    TranslateModule,
    DateTimePipe,
    AppInputDirective,
    BadgeComponent,
    CheckComponent,
    DateComponent
  ]
})
export class AddonComponent implements OnInit {
  @Input() isAdmin: boolean;
  @Input() addon: Addon;
  @Input() addonDiscount: AddonDiscount;
  @Input() price: CalculatedPrices;
  @Input() currency: string;
  @Input() dueDate: string;
  @Input() isSelected = false;
  @Input() recalculatingPrice = false;
  @Input() disabled = false;
  @Input() isYearlySelected = false;
  @Input() tooltip = '';

  @Output() toggleAddon = new EventEmitter<Addon>();
  @Output() discountChange = new EventEmitter<AddonDiscount>();

  @ViewChild(NgControl, { static: true }) checkControl: NgControl;

  public readonly ADDON_DISCOUNT_CONFIG = ADDON_DISCOUNT_CONFIG;
  public readonly addonPriceTypes = AddonPriceType;

  public minDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  get priceNet() {
    return this.isYearlySelected
      ? this.price?.yearlyPriceNet
      : this.price?.monthlyPriceNet;
  }

  get priceDiscountNet() {
    return this.isYearlySelected
      ? this.price?.postDiscountYearlyPriceNet
      : this.price?.postDiscountMonthlyPriceNet;
  }

  get setupPrice() {
    return this.price?.setup;
  }

  ngOnInit() {
    this.checkControl.control.patchValue(this.isSelected);
  }

  onChange(_value: boolean) {
    this.toggleAddon.emit(this.addon);
  }

  public onDiscountValueChange(value: number, addonDiscount: AddonDiscount) {
    this.discountChange.emit({
      ...addonDiscount,
      value
    });
  }

  public onDiscountDateChange(endDate, addonDiscount: AddonDiscount) {
    this.discountChange.emit({
      ...addonDiscount,
      endDate
    });
  }

  public isExpired(discount: AddonDiscount) {
    return (
      discount.value > 0 &&
      new Date(discount.endDate).getTime() < new Date().getTime()
    );
  }
}
