import { Component, Input } from '@angular/core';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelfDisclosureQuestion } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import {
  AppInputDirective,
  FormFieldComponent,
  FormFieldLabelComponent
} from '../../../components/legacy';

@Component({
  selector: 'app-self-disclosure-flat',
  templateUrl: './self-disclosure-flat.component.html',
  styleUrls: ['./self-disclosure-flat.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective
  ]
})
export class SelfDisclosureFlatComponent {
  @Input() selfDisclosureQuestion: SelfDisclosureQuestion;
  @Input() form: FormGroup;

  public get getFormControl() {
    return this.form.get('answer') as FormGroup;
  }
}
