import { Action } from '@ngrx/store';

import { ChangePasswordPayload, StateAfterAuth } from '@ui/shared/models';
import { AuthToken } from 'libs/infrastructure/keycloak-authentication-module/model';

export const USER_LOGIN = '[Auth] Login';
export const USER_LOGIN_SUCCESS = '[Auth] User Login Success';
export const USER_LOGIN_FAIL = '[Auth] User Login Fail';

export class UserLogin implements Action {
  readonly type = USER_LOGIN;

  constructor(
    public userCredentials: {
      email?: string;
      redirectUri: string;
      loginMethod?: string;
    }
  ) {}
}

export class UserLoginSuccess implements Action {
  readonly type = USER_LOGIN_SUCCESS;

  constructor(
    public token: AuthToken,
    public stateAfterAuth: StateAfterAuth
  ) {}
}

export class UserLoginFail implements Action {
  readonly type = USER_LOGIN_FAIL;

  constructor(public loginError: string) {}
}

export const SAVE_KEYCLOAK_URL = '[Auth] Save Keycloak Url';

export class SaveKeycloakUrl implements Action {
  readonly type = SAVE_KEYCLOAK_URL;
  constructor(public url: string) {}
}

export const USER_LOGOUT = '[Auth] User Logout';
export const USER_REDIRECT = '[Auth] User Redirect';
export const USER_LOGOUT_FAIL = '[Auth] User Logout Fail';
export const USER_LOGOUT_SUCCESS = '[Auth] User Logout Success';

export class UserLogout implements Action {
  readonly type = USER_LOGOUT;

  constructor(public redirectUrl?: string) {}
}

export class UserRedirect implements Action {
  readonly type = USER_REDIRECT;

  constructor(public pathAfterAuth?: any) {}
}

export class UserLogoutFail implements Action {
  readonly type = USER_LOGOUT_FAIL;

  constructor(public payload: any) {}
}

export class UserLogoutSuccess implements Action {
  readonly type = USER_LOGOUT_SUCCESS;

  constructor(
    public redirectUrl: string,
    public internalPoolReturnUrl?: string
  ) {}
}

export const USER_UPDATE_LAST_LOGIN = '[Auth] User Update Last Login';
export const USER_UPDATE_LAST_LOGIN_SUCCESS =
  '[Auth] User Update Last Login Success';

export class UserUpdateLastLogin implements Action {
  readonly type = USER_UPDATE_LAST_LOGIN;
}

export class UserUpdateLastLoginSuccess implements Action {
  readonly type = USER_UPDATE_LAST_LOGIN_SUCCESS;
}

export const REQUEST_PASSWORD_RESET = '[Auth] Request Password Reset';
export const REQUEST_PASSWORD_RESET_FAIL = '[Auth] Request Password Reset Fail';
export const REQUEST_PASSWORD_RESET_SUCCESS =
  '[Auth] Request Password Reset Success';

export class RequestPasswordReset implements Action {
  readonly type = REQUEST_PASSWORD_RESET;
  constructor(public email: string) {}
}

export class RequestPasswordResetSuccess implements Action {
  readonly type = REQUEST_PASSWORD_RESET_SUCCESS;
}

export class RequestPasswordResetFail implements Action {
  readonly type = REQUEST_PASSWORD_RESET_FAIL;
  constructor(public error: Error) {}
}

export const RESET_PASSWORD = '[Auth] Reset Password';
export const RESET_PASSWORD_FAIL = '[Auth] Reset Password Fail';
export const RESET_PASSWORD_SUCCESS = '[Auth] Reset Password Success';

export class ResetPassword implements Action {
  readonly type = RESET_PASSWORD;
  constructor(public resetPasswordPayload: ChangePasswordPayload) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordFail implements Action {
  readonly type = RESET_PASSWORD_FAIL;
  constructor(public error: Error) {}
}

export const CONFIRM_EMAIL = '[Auth] Confirm Email';
export const CONFIRM_EMAIL_FAIL = '[Auth] Confirm Email Fail';
export const CONFIRM_EMAIL_SUCCESS = '[Auth] Confirm Email Success';

export class ConfirmEmail implements Action {
  readonly type = CONFIRM_EMAIL;

  constructor(public token: string) {}
}

export class ConfirmEmailFail implements Action {
  readonly type = CONFIRM_EMAIL_FAIL;

  constructor(public error: Error) {}
}

export class ConfirmEmailSuccess implements Action {
  readonly type = CONFIRM_EMAIL_SUCCESS;
}

export const VERIFY_EMAIL = '[Auth] Verify Email';
export const VERIFY_EMAIL_FAIL = '[Auth] Verify Email Fail';
export const VERIFY_EMAIL_SUCCESS = '[Auth] Verify Email Success';

export class VerifyEmail implements Action {
  readonly type = VERIFY_EMAIL;
  constructor(public token: string) {}
}

export class VerifyEmailSuccess implements Action {
  readonly type = VERIFY_EMAIL_SUCCESS;
}

export class VerifyEmailFail implements Action {
  readonly type = VERIFY_EMAIL_FAIL;
  constructor(public error: Error) {}
}

export type AuthAction =
  | UserLogin
  | UserLoginSuccess
  | UserLoginFail
  | SaveKeycloakUrl
  | UserLogout
  | UserRedirect
  | UserLogoutFail
  | UserLogoutSuccess
  | UserUpdateLastLogin
  | UserUpdateLastLoginSuccess
  | RequestPasswordReset
  | RequestPasswordResetFail
  | RequestPasswordResetSuccess
  | ResetPassword
  | ResetPasswordFail
  | ResetPasswordSuccess
  | ConfirmEmail
  | ConfirmEmailFail
  | ConfirmEmailSuccess
  | VerifyEmail
  | VerifyEmailFail
  | VerifyEmailSuccess;
