import {
  Directive,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  HostListener,
  inject
} from '@angular/core';

@Directive({
  selector: '[appClickDetector]',
  standalone: true
})
export class ClickDetectorDirective {
  private elementRef = inject(ElementRef);

  @Input() enabled = false;
  @Output() insideClick = new EventEmitter<void>();
  @Output() outsideClick = new EventEmitter<void>();

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    if (this.enabled) {
      const clickedInside = (
        this.elementRef.nativeElement as HTMLElement
      ).contains(target);

      if (!clickedInside) {
        this.outsideClick.emit();
      } else {
        this.insideClick.emit();
      }
    }
  }
}
