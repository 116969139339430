import { Component, OnInit, inject } from '@angular/core';
import {
  NgbActiveModal,
  NgbNav,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MessageTemplate } from 'libs/components/legacy/messenger/model/interface';
import { DEFAULT_CONVERSATION_RESTRICTION_CONFIG } from 'libs/components/legacy/messenger/model/enum';
import { stripTypenameProperty } from 'libs/utils';
import { TranslateModule } from '@ngx-translate/core';
import { UserSettingsConversationConfig } from '@ui/shared/models';
import { ModalFooterComponent } from '../../../modal/modal-footer/modal-footer.component';
import { MessageTemplatesComponent } from '../message-templates/message-templates.component';
import { ButtonComponent } from '../../../../atoms/button/button.component';
import { ConversationRestrictionConfigFormComponent } from '../conversation-restriction-config-form/conversation-restriction-config-form.component';
import { ModalContentComponent } from '../../../modal/modal-content/modal-content.component';
import { ModalComponent } from '../../../modal/modal.component';
import { ConversationGeneralConfigFormComponent } from './conversation-general-config-form/conversation-general-config-form.component';

@Component({
  selector: 'app-chat-settings',
  templateUrl: './chat-settings-modal.component.html',
  styleUrls: ['./chat-settings-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    ModalContentComponent,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    ConversationGeneralConfigFormComponent,
    FormsModule,
    ReactiveFormsModule,
    ConversationRestrictionConfigFormComponent,
    ButtonComponent,
    MessageTemplatesComponent,
    NgbNavOutlet,
    ModalFooterComponent,
    TranslateModule
  ]
})
export class ChatSettingsModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);

  public restrictionConfigFormControl: FormControl;
  public generalConfigFormControl: FormControl;
  public userSettingsConversationConfig: UserSettingsConversationConfig;
  public activeTab: string;
  public conversationMessageTemplateSubstitutionTags: any;
  public newTemplateData: MessageTemplate;

  public save: (messageTemplate: UserSettingsConversationConfig) => void;

  public saveControlValues() {
    return this.save({
      ...this.generalConfigFormControl.value,
      restrictionConfig: this.restrictionConfigFormControl.value
    });
  }

  public ngOnInit() {
    const { restrictionConfig, ...generalConfig } =
      this.userSettingsConversationConfig || {};

    this.generalConfigFormControl = new FormControl(
      stripTypenameProperty(generalConfig)
    );

    const strippedRestrictionConfig = stripTypenameProperty(restrictionConfig);
    this.restrictionConfigFormControl = new FormControl(
      restrictionConfig &&
      !Object.values(strippedRestrictionConfig).every(c => !c)
        ? strippedRestrictionConfig
        : DEFAULT_CONVERSATION_RESTRICTION_CONFIG
    );
  }

  public controlsNeverTouched() {
    return !(
      this.restrictionConfigFormControl.touched ||
      this.generalConfigFormControl.touched
    );
  }

  public close() {
    this.ngbActiveModal.close();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
